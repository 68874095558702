<template>
  <b-sidebar
    id="add-new-template-sidebar"
    :visible="isAddNewTemplateSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-template-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          إضافة {{ $t("template") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- name -->

          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group
              label="اسم"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="templateData.name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- end name -->
          <!-- description -->

          <validation-provider
            #default="validationContext"
            name="features"
            rules="required"
          >
            <b-form-group
              label="الأعمدة"
              label-for="columns"
            >
              <v-select
                v-model="templateData.features"
                multiple="multiple"
                dir="rtl"
                :options="featureOptions"
                :clearable="true"
                input-id="client"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- end description -->

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t("save") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BButton,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ref } from '@vue/composition-api';
import { required, alphaNum, validEmail } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormTextarea,
    BButton,
    vSelect,
    flatPickr,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewTemplateSidebarActive',
    event: 'update:is-add-new-template-sidebar-active',
  },
  props: {
    isAddNewTemplateSidebarActive: {
      type: Boolean,
      required: true,
    },
    featureOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      validEmail,
      config: {
        locale: Arabic,
      },
    };
  },
  methods: {
    orientationChanged(orientation) {
      this.templateData.orientation = orientation;
    },
    sizeChanged(size) {
      this.templateData.size = size;
    },
    templateChanged(template) {
      this.templateData.template_id = template;
    },
    templateChanged(template) {
      this.templateData.template_id = template;
    },

    onSubmit() {
      this.$refs.refFormObserver
        .validate()
        .then(async (success) => {
          if (success) {
            store.dispatch('template/addTemplate', this.templateData).then((response) => {
              this.$toast.success(response.data.message);
              this.$emit('refetch-data');
              this.$emit('update:is-add-new-template-sidebar-active', false);
            })
              .catch((err) => {
                if (err.response) {
                  if (err.response.status === 422) {
                    this.$toast.error(err.response.data.message);
                    this.$refs.refFormObserver.setErrors(err.response.data.errors);
                  } else {
                    this.$toast.error(err.response.data.error);
                  }
                }
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  setup(props, { emit }) {
    const blankTemplateData = {
      template_id: '',
      name: '',
      description: '',
      features: [],
    };

    const templateData = ref(JSON.parse(JSON.stringify(blankTemplateData)));
    const resettemplateData = () => {
      templateData.value = JSON.parse(JSON.stringify(blankTemplateData));
    };

    const templates = ref([]);
    const res = store.dispatch('template/fetchTemplates').then((response) => {
      const { data } = response;
      templates.value = data.map((c) => ({
        label: c.description,
        value: c.id,
        ...c,
      }));
    });

    const { refFormObserver, getValidationState, resetForm } = formValidation(resettemplateData);

    return {
      templateData,
      templates,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#add-new-template-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
